import guards from './guards';

const Layout = () => import(/* webpackChunkName: "layout" */'../layouts/BlankLayout.vue');
const Interview = () => import(/* webpackChunkName: "bots" */'../views/interview/Interview.vue');
const Report = () => import(/* webpackChunkName: "bots" */'../views/interview/Report.vue');

export default {
    path: '/',
    name: 'DashboardLayout',
    redirect: '/app/',
    component: Layout,
    beforeEnter: guards.loggedInGuard,
    children: [
        { path: '/reports/', name: 'Report', component: Report },
        { path: '/', name: 'Interview', component: Interview }
    ]
};
