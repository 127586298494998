const baseUrl = '/api/';

export default {
    auth: {
        login: baseUrl + 'auth/login/',
        status: baseUrl + 'auth/status/'
    },

    interview: {
        report: baseUrl + 'interviews/report/',
        question: baseUrl + 'interviews/question/',
        questions: baseUrl + 'interviews/questions/',
        answer: baseUrl + 'interviews/answer/'
    }
};
